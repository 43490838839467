<template>
    <b-container fluid>
      <b-row>
        <LayoutHeader />
        <b-col sm="12">
          <b-row>
            <b-col lg="3" class="profile-left">
              <ServicesCard />
            </b-col>
            <b-col lg="6" class="profile-center">
              <iq-card header-class = "d-flex align-items-center justify-content-between">
                <template v-slot:headerTitle>
                    <h4 class="card-title mb-0">{{$t('select-features')}}</h4>
                </template>
                <template v-slot:headerAction>
<!--                  <button class="bg-transparent border-0" @click="selectAllFeature()">-->
<!--                    <span class="cursor-pointer">-->
<!--                      Select All-->
<!--                    </span>-->
<!--                  </button>-->
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col cols = "6" v-for="feature in features.data" :key="feature.id" class="mb-3">
                            <div class="featur p-3 h-100">
                                <div class="title  d-flex align-items-center">
                                    <b-form-checkbox :value="feature.id" v-model="featureIds" name="check-button" />
                                    <h5 class="">{{ feature[`${$i18n.locale}_name`] }}</h5>
                                    <i :class="feature.icon"></i>
                                </div>
                                <div class="sub-feature mt-2 d-flex align-items-center flex-wrap" v-if="feature.features.length">
                                    <div class="content d-flex px-2 align-items-center" @click="addFeature(sub.id)" :class="featureIds.includes(sub.id)? 'active' : ''" v-for="sub in feature.features" :key="sub.id">

                                        <span class="">
                                            {{ sub[`${$i18n.locale}_name`]}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-col>

                        <b-col sm="12">
                          <div class="pagination d-flex justify-content-center pt-4">
                              <b-pagination
                                  dir="ltr"
                                  @change="getFeatures(page)"
                                  v-model="page"
                                  :total-rows="features.total"
                                  :per-page="features.per_page"
                              ></b-pagination>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="controll py-2 d-flex justify-content-center">
                                <b-button variant="primary" @click="submit">{{$t('save')}}</b-button>
                            </div>
                        </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="3" class="profile-right">
              <MapCard />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
import ServicesCard from '@/components/hotelsComponents/ServicesCard.vue'
import MapCard from '@/components/hotelsComponents/MapCard.vue'
export default {
  components: {
    LayoutHeader,
    ServicesCard,
    MapCard
  },
  data () {
    return {
      page: 1,
      featureIds: []
    }
  },
  methods: {
    selectAllFeature () {
      this.features.data.map((el) => {
        this.featureIds.push(el.id)
      })
    },
    submit () {
      this.addHotelFeatures({
        featureIds: this.featureIds
      })
    },
    addFeature (id) {
      if (this.featureIds.includes(id)) {
        this.featureIds = this.featureIds.filter(el => el !== id)
      } else {
        this.featureIds.push(id)
      }
    }
  },
  mounted () {
    this.getFeatures()
    this.getHotel().then(() => {
      this.hotel.features.map((el) => {
        this.featureIds.push(el.id)
      })
    })
  }
}
</script>
<style>

    .featur {
        background-color: #f9f9f9;
        border-radius: 15px;
        margin-bottom: 8px;
    }
    .featur .sub-feature {
        gap:10px
    }
    .featur .content {
        border:1px solid var(--primary);
        color:var(--primary);
        border-radius: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap:5px;
        cursor: pointer;
    }
    .featur .content.active {
        background-color:var(--primary);
        color:#fff;
    }

</style>
